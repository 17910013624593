import styles from './VModalFreeConsultation.module.css'

// Modal
import { useModalWindow } from "react-modal-global";
import useScrollBlock from "../../../../../customHooks/useScrollBlock";
import PopupAppearLayout from "../Layouts/PopupAppearLayout";

// Helmet
import { setDefaultThemeColor } from '../../../../../features/helmet/helmetSlice';
import { useDispatch, useSelector } from 'react-redux';
// Form
import { useForm } from "react-hook-form"
// Redux
import { useAddNewPatientMutation, useUpdatePatientMutation } from '../../../../../features/users/usersApiSlice';
// Toastr
import { toast, Zoom} from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
// Functions
import { convertIsoToDateStr, getAgeEnding, translate } from '../../../../../customHooks/functions';
import { selectCurrentLanguage } from '../../../../../features/auth/authSlice';



const FreeConsultation = ({  }) => {
    const language = useSelector(selectCurrentLanguage)

    const modal = useModalWindow();
    const [blockScroll, allowScroll] = useScrollBlock();

    const dispatch = useDispatch()
    const defaultThemeColor = () => dispatch(setDefaultThemeColor())

    const closeModal = () => {
      // setTimeout(function(){allowScroll()}, 150);
      defaultThemeColor()
      allowScroll()
      modal.close()
    }   
    
    const notifyUpdate = () => {
        toast.success(translate("dataUpdated", language), {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
                transition: Zoom,
            });
    };
    // ///////////////////////////////
    // Toastr
    const notify = () => {
        toast.success(translate("patientCreated", language), {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            transition: Zoom,
            });
      };


      const {
        register,
        formState: { errors },
        handleSubmit,
        reset
    } = useForm({
        mode: "onSubmit",
        // defaultValues: {
        //     email: 'shm90@mail.ru',
        //     password: '123456'
        // }
    })
    
    const onSubmit = async (data) => {
        console.log(data)
        // try {
        //   const { email, password } = data
        //   const { accessToken } = await login({ email, password }).unwrap()
        //   dispatch(setCredentials({ accessToken }))
        //   reset()
        //   navigate('/admin/dashboard')
        // } catch (error) {
        //   console.log(error)
        // }
        
    }
    
    return (
        <PopupAppearLayout classNameLayout={styles.popup__layout}>
            
            <form className={styles.formLogin} onSubmit={handleSubmit(onSubmit)}>
                    <div className={styles.title}>Бесплатная консультация</div>
                    <div className={styles.title}>Получите профессиональные рекомендации и ответы на Ваши вопросы для уверенного старта проекта.</div>
                    
                    <div className={styles.form_loginFieldsBlock}>
                        <div className={styles.form_loginFields_row}>
                            {/* <label htmlFor='email'>Логин<span className={styles.text_red}>*</span></label> */}
                            <input
                                type='text'
                                className={styles.form_inputField}
                                id='email'
                                placeholder='Эл. почта'
                                {
                                ...register("email",
                                { 
                                    required: "Email is required",
                                    pattern:  {
                                                value: /^([a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/,
                                                message: "Not valid email"
                                            }  
                                }                      
                                )}
                                aria-invalid={errors.email ? "true" : "false"}
                            />
                        </div>  
                        <p>{errors.email?.message}</p>

                        <div className={styles.form_loginFields_row}>
                            {/* <label htmlFor='password'>Пароль<span  className={styles.text_red}>*</span></label> */}
                            <input
                                type='password'
                                placeholder='Пароль'
                                id='password'
                                className={styles.form_inputField}
                                {...register("password", 
                                { 
                                    required: "Password is required" 
                                }
                                )}
                                aria-invalid={errors.password ? "true" : "false"}
                            />
                        </div>
                        {errors.password?.type === "required" && (
                        <p>{errors.password?.message}</p>
                        )}
                    </div>

                    <button className={styles.btn_solid_blue}>
                    Войти
                    </button>

                </form>

            
        </PopupAppearLayout>
    );         
}

export default FreeConsultation;
